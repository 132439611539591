<template>
  <div class="add-car">
    <div class="add-car__form">
      <ui-container class="add-car__form-content">
        <delete-car-input />
        <div
          style="
            align-items: start;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: column;
            grid-gap: .5em;"
        >
          <ui-button @click="submit" :is-disabled="isSubmitting">
            {{ $t('SUBMIT_BTN') }}
          </ui-button>
        </div>
      </ui-container>
    </div>
  </div>
</template>

<script>
import DeleteCarInput from './components/DeleteCarInput'

import { UiContainer, UiButton } from '@shelf.network/ui-kit'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { actions, mutations, getters } from './store/types'
import { showError, showSuccess } from 'Utils/notifications'
import { scrollToError } from 'Utils/scrollTo'
import { i18n } from 'I18n/instance'
import axios from 'axios'

export default {
  name: 'delete-car',

  components: {
    UiContainer,
    UiButton,
    DeleteCarInput
  },

  data: (_) => ({
    isLoading: false,
    isSubmitting: false
  }),
  computed: {
    ...mapGetters('ui/del-car', {
      form: getters.form,
      errors: getters.errors
    })
  },
  methods: {
    ...mapActions('ui/del-car', {
      createLot: actions.CREATE_LOT,
      validateForm: actions.VALIDATE_FORM
    }),

    ...mapMutations('ui/del-car', {
      setFormField: mutations.SET_FORM_FIELD,
      resetForm: mutations.RESET_STATE
    }),

    async submit() {
      if (!(await this.validateForm())) {
        return
      }
      const { delId, delIds } = this.form
      if (delId !== delIds) {
        showError(i18n.t('TOASTS.CAR_DEL_INPUT'))
        return
      }
      this.isSubmitting = true
      const token = localStorage.getItem('token')
      try {
        const res = await axios.delete(
          'https://api.shelf.network/front-office/lots/' + delId,
          {
            headers: {
              Authorization: `Bearer ${token}` // 通过 Bearer 方式传递 token
            }
          }
        )
        if (res.status === 200) {
          showSuccess(i18n.t('TOASTS.CAR_DEL_SUCCESS'))
          this.resetForm()
        } else {
          showError(i18n.t('TOASTS.CAR_DEL_FAIL'))
        }
        this.isSubmitting = false
        console.log('res', res)
      } catch (err) {
        console.log(err)
        showError(i18n.t('TOASTS.CAR_DEL_FAIL'))
        this.isSubmitting = false
      }

      // if (res.data) {

      // } else {
      //   showError('should be a valid integer')
      // }
      //    "errors": [
      //     {
      //         "title": "Bad Request",
      //         "status": "400",
      //         "meta": {
      //             "error": "should be a valid integer",
      //             "field": "lot_id"
      //         }
      //     }
      // ]
      // this.isSubmitting = true
      // try {
      //   await this.createLot()
      //   showSuccess(i18n.t('TOASTS.CREATE_LOT_MSG'))

      //   this.resetForm()
      // } catch (e) {
      //   console.error(e)
      //   showError(i18n.t('TOASTS.CREATE_LOT_ERROR'))
      // }
      // this.isSubmitting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-car {
  &__form-content {
    padding-top: 6em;
    width: 100%;
    max-width: 70rem;
    display: grid;
    grid-gap: 5em;
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "HEADING": "To list your vehicle, we need some details:",
    "SUBHEADING": "Your vehicle will be subject of instant leasing and our financing partner will facilitate the match",
    "PROMOTED_SUCCESSFUL_TITLE": "THANKS!",
    "PROMOTED_SUCCESSFUL_MSG": "Your vehicle has been promoted and automatically approved",
    "META_TITLE": "Add A Car",
    "META_DESCRIPTION": "Upload your car for sale, your vehicle will be subject of instant leasing and our financing partner will facilitate the match for you.",
    "DIRECT_SALE_LINK": "DIRECT SALE",
    "AUCTION_LINK": "AUCTION",
    "SUBMIT_BTN": "Remove"
  },
  "ka": {
    "HEADING": "თქვენი ავტომობილის დასამატებლად შეავსეთ ველები:",
    "SUBHEADING": "შენი ავტომობილი გაიყიდება ლიზინგით და პოტენციურ მყიდველს დაუმტკიცდება დაფინანსება",
    "PROMOTED_SUCCESSFUL_TITLE": "ᲛᲐᲓᲚᲝᲑᲐ!",
    "PROMOTED_SUCCESSFUL_MSG": "თქვენი ვეტერინარი დაწინაურებულია\n და ავტომატურად დამტკიცებულია",
    "META_TITLE": "ატვირთვა",
    "META_DESCRIPTION": "ატვირთე ავტომობილი და დააჩქარე გაყიდვა. უფასო განცხადების განთავსება. ატვირთეთ მანქანის სურათები და თქვენს ავტომობილზე, მყიდველისთვის გავრცელდება ავტომატური ლიზინგი ჩვენი ფინანსური პარტნიორის მიერ.",
    "DIRECT_SALE_LINK": "გაყიდვა",
    "AUCTION_LINK": "აუქციონი",
    "SUBMIT_BTN": "ამოღება"
  },
  "ru": {
    "HEADING": "Добавить авто",
    "SUBHEADING": "Ваш автомобиль будет предметом мгновенного лизинга, и наш финансовый партнер будет содействовать проведению сделки.",
    "PROMOTED_SUCCESSFUL_TITLE": "СПАСИБО!",
    "PROMOTED_SUCCESSFUL_MSG": "Ваше авто продвинуто и автоматически подтверждено",
    "META_TITLE": "Добавить авто",
    "META_DESCRIPTION": "Выставляйте своё авто на продажу. Наши финансовые партнеры предоставят лизинг вашим покупутелям.",
    "DIRECT_SALE_LINK": "ПРЯМАЯ ПРОДАЖА",
    "AUCTION_LINK": "АУКЦИОН",
    "SUBMIT_BTN": "удалять"
  },
  "uk": {
    "HEADING": "Додати авто",
    "SUBHEADING": "Ваш автомобіль буде предметом миттєвого лізингу, а наш партнер з фінансування сприятиме проведенню угоди",
    "PROMOTED_SUCCESSFUL_TITLE": "ДЯКУЄМО!",
    "PROMOTED_SUCCESSFUL_MSG": "Вашу автівку просунуто та автоматично підтверджено",
    "META_TITLE": "Додати авто",
    "META_DESCRIPTION": "Виставляйте своє авто на продаж. Наші финансові партнери нададуть лізинг вашим покупцям.",
    "DIRECT_SALE_LINK": "ПРЯМИЙ ПРОДАЖ",
    "AUCTION_LINK": "АУКЦІОН",
    "SUBMIT_BTN": "видалити"
  }
}
</i18n>
