import { i18n } from 'I18n/instance'

const TRANSLATION_ID_KEYWORD_MAP = {
  falseSchema: 'JSON_SCHEMA_ERRORS.FALSE_SCHEMA',
  $ref: 'JSON_SCHEMA_ERRORS.$REF',
  additionalItems: 'JSON_SCHEMA_ERRORS.ADDITIONAL_ITEMS',
  additionalProperties: 'JSON_SCHEMA_ERRORS.ADDITIONAL_PROPERTIES',
  anyOf: 'JSON_SCHEMA_ERRORS.ANY_OF',
  dependencies: 'JSON_SCHEMA_ERRORS.DEPENDENCIES',
  enum: 'JSON_SCHEMA_ERRORS.ENUM',
  format: 'JSON_SCHEMA_ERRORS.FORMAT',
  'format/vin': 'JSON_SCHEMA_ERRORS.FORMAT_VIN',
  'format/delId': 'JSON_SCHEMA_ERRORS.FORMAT_DELId',
  'format/delIds': 'JSON_SCHEMA_ERRORS.FORMAT_DELId',
  'format/youTubeLink': 'JSON_SCHEMA_ERRORS.FORMAT_YOUTUBE_LINK',
  'format/name': 'JSON_SCHEMA_ERRORS.FORMAT_NAME',
  'format/password': 'JSON_SCHEMA_ERRORS.FORMAT_PASSWORD',
  'format/phone': 'JSON_SCHEMA_ERRORS.FORMAT_PHONE',
  'format/email': 'JSON_SCHEMA_ERRORS.FORMAT_EMAIL',
  'format/location': 'JSON_SCHEMA_ERRORS.FORMAT_LOCATION',
  'format/uri': 'JSON_SCHEMA_ERRORS.FORMAT_URI',
  'format/secondhandDealPermissionNumber':
    'JSON_SCHEMA_ERRORS.FORMAT_SECONDHAND_DEAL_PERMISSION_NUMBER',
  maximum: 'JSON_SCHEMA_ERRORS.MAXIMUM',
  exclusiveMaximum: 'JSON_SCHEMA_ERRORS.EXCLUSIVE_MAXIMUM',
  minimum: 'JSON_SCHEMA_ERRORS.MINIMUM',
  exclusiveMinimum: 'JSON_SCHEMA_ERRORS.EXCLUSIVE_MINIMUM',
  maxItems: 'JSON_SCHEMA_ERRORS.MAX_ITEMS',
  minItems: 'JSON_SCHEMA_ERRORS.MIN_ITEMS',
  maxLength: 'JSON_SCHEMA_ERRORS.MAX_LENGTH',
  minLength: 'JSON_SCHEMA_ERRORS.MIN_LENGTH',
  maxProperties: 'JSON_SCHEMA_ERRORS.MAX_PROPERTIES',
  minProperties: 'JSON_SCHEMA_ERRORS.MIN_PROPERTIES',
  multipleOf: 'JSON_SCHEMA_ERRORS.MULTIPLE_OF',
  not: 'JSON_SCHEMA_ERRORS.NOT',
  oneOf: 'JSON_SCHEMA_ERRORS.ONE_OF',
  pattern: 'JSON_SCHEMA_ERRORS.PATTERN',
  required: 'JSON_SCHEMA_ERRORS.REQUIRED',
  type: 'JSON_SCHEMA_ERRORS.TYPE',
  uniqueItems: 'JSON_SCHEMA_ERRORS.UNIQUE_ITEMS',
  custom: 'JSON_SCHEMA_ERRORS.CUSTOM',
  propertyNames: 'JSON_SCHEMA_ERRORS.PROPERTY_NAMES',
  patternRequired: 'JSON_SCHEMA_ERRORS.PATTERN_REQUIRED',
  switch: 'JSON_SCHEMA_ERRORS.SWITCH',
  const: 'JSON_SCHEMA_ERRORS.CONST',
  contains: 'JSON_SCHEMA_ERRORS.CONTAINS',
  formatMaximum: 'JSON_SCHEMA_ERRORS.FORMAT_MAXIMUM',
  formatMinimum: 'JSON_SCHEMA_ERRORS.FORMAT_MINIMUM',
  formatExclusive_maximum: 'JSON_SCHEMA_ERRORS.FORMAT_EXCLUSIVE_MAXIMUM',
  formatExclusive_minimum: 'JSON_SCHEMA_ERRORS.FORMAT_EXCLUSIVE_MINIMUM',
  if: 'JSON_SCHEMA_ERRORS.IF'
}

export function jsvLocalizeError(jsvError) {
  if (!jsvError) {
    return ''
  }

  const error = jsvError || {}
  // TODO: check for the JsvError instance
  if (!error.keyword && !error.params) {
    console.warn(
      `jsvLocalizeError(): invalid error format, should have keyword and params properties, got: ${JSON.stringify(
        error
      )}`
    )
    return error
  }

  if (error.translatedMsg) {
    return error.translatedMsg
  }

  if (error.translationId) {
    return i18n.t(error.translationId, error.translationParams)
  }

  let keyword = error.keyword
  if (keyword === 'format') {
    const formatKeyword = `format/${error.params.format}`
    if (TRANSLATION_ID_KEYWORD_MAP[formatKeyword]) {
      keyword = formatKeyword
    }
  }

  const translationId = TRANSLATION_ID_KEYWORD_MAP[keyword]
  // eslint-disable-next-line vue-i18n/no-dynamic-keys
  const result = i18n.t(translationId, error.params)

  if (result === translationId) {
    return error.message
  }

  return result
}
