<template>
  <section class="add-car__section">
    <h4 class="add-car__section-heading">
      {{ $t('TITLE') }}
    </h4>

    <div class="add-car__section-content">
      <div class="add-car__form-row">
        <ui-text
          :placeholder="$t('INPUT_PH')"
          :value="form.delId"
          :is-error="Boolean(errors.delId)"
          @input="setFormField({ field: 'delId', value: $event })"
          class="add-car__form-field"
          type="number"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.delId) }}
          </span>
        </ui-text>
      </div>
    </div>
    <div class="add-car__section-content">
      <div class="add-car__form-row">
        <ui-text
          :placeholder="$t('INPUT_PH')"
          :value="form.delIds"
          :is-error="Boolean(errors.delIds)"
          @input="setFormField({ field: 'delIds', value: $event })"
          class="add-car__form-field"
          type="number"
        >
          <span slot="error">
            {{ jsvLocalizeError(errors.delIds) }}
          </span>
        </ui-text>
      </div>
    </div>
  </section>
</template>

<script>
import { UiText } from '@shelf.network/ui-kit'

import { mapGetters, mapMutations } from 'vuex'
import { getters, mutations } from '../store/types'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

export default {
  name: 'del-car-technical',
  components: {
    UiText
  },
  computed: {
    ...mapGetters('entities/enums', {}),
    ...mapGetters('ui/del-car', {
      form: getters.form,
      errors: getters.errors
    })
  },
  methods: {
    ...mapMutations('ui/del-car', {
      setFormField: mutations.SET_FORM_FIELD
    }),

    jsvLocalizeError
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/add-car-form';
</style>

<i18n>
{
  "en": {
    "TITLE": "Input the id. The two inputs need to be consistent.",
    "MANUFACTURER_PH": "Manufacturer",
    "MODEL_PH": "Model",
    "FIRST_REGISTRATION_YEAR_PH": "First Registration Year",
    "FUEL_TYPE_PH": "Fuel Type",
    "ENGINE_CAPACITY_PH": "Engine Capacity",
    "MILEAGE_PH": "Mileage",
    "WHEEL_PH": "Wheel",
    "INPUT_PH": "ID Number",
    "TRANSMISSION_PH": "Transmission",
    "COLOR_PH": "Color"
  },
  "ka": {
    "TITLE": "შეიყვანეთ ID. ორი შეყვანა უნდა იყოს თანმიმდევრული.",
    "MANUFACTURER_PH": "მწარმოებელი",
    "MODEL_PH": "მოდელი",
    "FIRST_REGISTRATION_YEAR_PH": "რეგისტრაციის წელი",
    "FUEL_TYPE_PH": "საწვავის ტიპი",
    "ENGINE_CAPACITY_PH": "ძრავის მოცულობა",
    "MILEAGE_PH": "გარბენი",
    "WHEEL_PH": "საჭე",
    "INPUT_PH": "ID ნომერი",
    "TRANSMISSION_PH": "გადაცემათა კოლოფი",
    "COLOR_PH": "ფერი"
  },
  "ru": {
    "TITLE": "Введите идентификатор. Два ввода должны быть согласованы.",
    "MANUFACTURER_PH": "Производитель",
    "MODEL_PH": "Модель",
    "FIRST_REGISTRATION_YEAR_PH": "Год первой регистрации",
    "FUEL_TYPE_PH": "Тип топлива",
    "ENGINE_CAPACITY_PH": "Объем двигателя",
    "MILEAGE_PH": "Пробег",
    "WHEEL_PH": "Расположение руля",
    "INPUT_PH": "идентификационный номер",
    "TRANSMISSION_PH": "Коробка передач",
    "COLOR_PH": "Цвет"
  },
  "uk": {
    "TITLE": "Введіть id. Два входи мають бути узгодженими.",
    "MANUFACTURER_PH": "Виробник",
    "MODEL_PH": "Модель",
    "FIRST_REGISTRATION_YEAR_PH": "Рік першої реєстрації",
    "FUEL_TYPE_PH": "Тип палива",
    "ENGINE_CAPACITY_PH": "Об'єм двигуна",
    "MILEAGE_PH": "Пробіг",
    "WHEEL_PH": "Розташування керма",
    "INPUT_PH": "ідентифікаційний номер",
    "TRANSMISSION_PH": "Трансмісія",
    "COLOR_PH": "Колір"
  }
}
</i18n>
